import React from "react"
import { Link } from "gatsby"
import GatsbyBackgroundImage from 'gatsby-background-image' 

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"


import '../main.scss'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

/** @jsx jsx */
import { css, jsx } from '@emotion/core'



// images
import nikeLogoBig from '../img/logo-nike-big.png'

import nikeImg2 from '../img/nike-img-2.jpg'
import nikeImg3 from '../img/nike-img-3.jpg'
import woodCollage from '../img/services-wood.jpg'

const NikePage = () => {
    const slickSettings = {
        dots:true,
        infinite:true,
        speed:500,
        slidesToShow:1,
        slidesToScroll:1
    }
    return (
    <Layout>
        <SEO title="Nike" />
        <div className="all">
            <div id="cases">
                <div id="nike" className="case">
                    <div className="close"><Link to="/">Go Back Home</Link></div>
                    <div className="mast shift">
                        <img src={nikeLogoBig} alt="NIKE" css={css`width:16% !important;`} />
                        <h1><span>NIKE</span></h1>
                        <h2>Catch relaunches a dormant category for a global power.</h2>
                    </div>
                    <div className="intro">
                        <p>Athletes everywhere are ready to be introduced to The Nike Vaporwing Elite, a product of two years of reinventing the brand design and manufacturing of performance eyewear.
                            <br /><br />
                            Catch was given the honor of telling this story, and began by speaking with hundreds of professional, collegiate, and dedicated amateur runners.  One key truth emerged: as the bodies of athletes continue to be pushed to the limits — their muscles and lungs honed to perfection — their eyes, a primary tool in their quest for excellence, are seemingly resistant to improvement.  We disagreed. Nike was investing in the athletes' vision, increasing their ability to focus on their every motion, protecting them from environmental distractions, and offering them both physical and psychological advantages over their competition.
                        </p>
                    </div>
                    <div className="work">
                        <ul>
                            <li>
                                <div className="section3">
                                    <div className="video">
                                        {/*<iframe src="https://player.vimeo.com/video/156712446" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>*/}
                                        <style dangerouslySetInnerHTML={{ __html: ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; height: auto; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }" }} /><div className="embed-container"><iframe src="https://player.vimeo.com/video/161195898" frameBorder={0} webkitallowfullscreen mozallowfullscreen allowFullScreen /></div>
                                        {/*<video preload="false" controls poster="/vid/vid-screen.jpg">
                                                            <source src="/vid/Catch_Loews.mp4" type="video/mp4" />
                                                            <source src="/vid/Catch_Loews.webm" type="video/webm" />
                                                            <source src="/vid/Catch_Loews.ogv" type="video/ogg" />
                                                        </video>*/}
                                    </div>
                                </div>
                            </li>
                            <li className="intro">
                                <p>
                                    The campaign itself also took a different path.  Amongst a barrage of short attention span bait, Nike and Catch believed that the journey that led to the birth of the Vaporwing Elite was worth telling from beginning to end, highlighting the brilliant minds that brought this next-generation product to life.
                                </p>
                            </li>
                            <li><img src={nikeImg2} alt="" /></li>
                            <li className="intro">
                                <p>
                                    The resulting work features a :30 launch spot that quite literally grabs eyeballs, and a series of short documentaries that tell the story from the perspective of the design team at Nike, eyewear giant Marchon, lens innovator Zeiss, and American 400-meter hurdler Bershawn Jackson. In the end, the world will once again understand that Nike goes to incredible lengths to answer the challenges posed by world-class athletes relentlessly striving to improve their performance.
                                    <br /><br />
                                    And Catch believes that they will want to hear the whole story of how "Your Eyes Have Evolved."
                                </p>
                            </li>
                            <li><img src={nikeImg3} alt="" /></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>{/* #all */}
    </Layout>
)}

export default NikePage
